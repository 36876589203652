import React, { useEffect } from "react";
import Slider from "react-slick";
import AOS from 'aos';
import 'aos/dist/aos.css';

// My CSS's
import "../styles/pricing.css";
import '../styles/globalSty.es.css'


function Pricing() {

  useEffect(() => {
    AOS.init({
      duration: 800,
      offset: 200,
      once: true
    });
  }, []);

  return (
    <div className="productos-section" data-aos="fade-up">
      <h1 className="text-white pixeloFont py-3">Pricing</h1>
      <div className="pricing-cards">
        <div className="card">
          <h2 className="text-center">Basic Pack </h2>
          <p className="price">
            U$S1250<span>/año</span>
          </p>
          <ul className="text-center">
            <li>
              Pagina web estatica para llevar tu negocio al siguiente nivel
            </li>
            <li>Acceso a diseños profesionales </li>
            <li>Soporte básico</li>
            <li>Mantenimiento Anual</li>
            <li>Hosting</li>
            <li>Dominio</li>
          </ul>
          {/* <button>Contactar a Ventas</button> */}
        </div>
        <div className="card">
          <h2 className="text-center">Estándar</h2>
          <p className="price">
            U$S1890<span>/año</span>
          </p>
          <ul className="text-center">
            <li>
              Pagina web completa para llevar tu negocio al siguiente nivel
            </li>
            <li>Acceso a diseños profesionales </li>
            <li>Soporte prioritario</li>
            <li>Actualizaciones Mensuales</li>
            <li>Mantenimiento Anual</li>
            <li>Hosting</li>
            <li>Dominio</li>
          </ul>
          {/* <button>
            <a
              href="https://wa.me/59891445491?text=Gracias%20por%20comunicarte%20con%20pixelo!%20Un%20Asesor%20de%20ventas%20se%20va%20a%20contactar%20contigo%20para%20Agendar%20una%20llamada%20y%20establecer%20las%20necesidades%20de%20tu%20negocio"
              target="_blank"
            >
Contactar a Ventas
            </a>
            
          </button> */}
        </div>
        <div className="card">
          <h2 className="text-center">Premium Plus</h2>
          <p className="price">
            U$S2750<span>/año</span>
          </p>
          <ul className="text-center">
            <li>Diseño avanzado para llevar tu negocio al siguiente nivel</li>
            <li>Acceso a diseños profesionales</li>
            <li>
              Acceso a ejecutivo de cuentas para manetenerte al tanto de tu
              proyecto cuando lo necesites
            </li>
            <li>Soporte prioritario</li>
            <li>Mantenimiento Anual</li>
            <li>Certificados SSL</li>
            <li>5 Casillas de Correo asociadas al Dominio</li>
            <li>Hosting</li>
            <li>Dominio</li>
          </ul>
          {/* <button>Contactar a Ventas</button> */}
        </div>
      </div>
    </div>
  );
}

export default Pricing;
