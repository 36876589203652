import React, { useState } from "react";
import "../styles/pixeloPreviewPage.css";
import pixeloLogo from "../assets/images/pixeloIcon_lg.png";
import PricingPage from "../components/pricing";
import InicioPage from "../components/inicio";
import Footer from "../components/footer";
import Contacto from "../components/contacto"

function PixeloSpa() {
  const [section, setSection] = useState("inicio");

  const changeSection = (newSection) => {
    setSection(newSection);
  };

  return (
    <div className="pixelo-spa">
      <nav className="navbar navbar-expand-lg navbar-dark">
        <div className="container">
          <button
            className="navbar-brand button-link"
            onClick={() => changeSection("inicio")}
          >
            <img src={pixeloLogo} alt="Pixelo" className="pixeloNavBarIcon" />
          </button>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto">
              <li
                className={`nav-item ${section === "inicio" ? "active" : ""}`}
              >
                <button
                  onClick={() => changeSection("inicio")}
                  className="nav-link button-link"
                >
                  Inicio
                </button>
              </li>
              <li
                className={`nav-item ${
                  section === "productos" ? "active" : ""
                }`}
              >
                <button
                  onClick={() => changeSection("productos")}
                  className="nav-link button-link"
                >
                  Pricing
                </button>
              </li>
              {/* <li
                className={`nav-item ${
                  section === "servicios" ? "active" : ""
                }`}
              >
                <button
                  onClick={() => changeSection("servicios")}
                  className="nav-link button-link"
                >
                  Servicios
                </button>
              </li> */}
              <li
                className={`nav-item ${section === "contacto" ? "active" : ""}`}
              >
                <button
                  onClick={() => changeSection("contacto")}
                  className="nav-link button-link"
                >
                  Contacto
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <main className="main-content">
        {section === "inicio" && <InicioPage />}
        {section === "productos" && <PricingPage />}
        {section === "servicios" && (
          <section className="servicios-section">
            <h1>Nuestros Servicios</h1>
            <ul>
              <li>Servicio 1</li>
              <li>Servicio 2</li>
              <li>Servicio 3</li>
            </ul>
          </section>
        )}
        {section === "contacto" && (
          <Contacto/>
        )}
      </main>

      <footer className="footer p-1">
        <Footer/>
      </footer>
    </div>
  );
}

export default PixeloSpa;
