import React, { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import pixeloLogo from "../assets/images/pixeloIcon_xs.png";
import "../styles/contacto.css";

function Contacto() {
  useEffect(() => {
    AOS.init({
      duration: 800,
      offset: 100,
      once: false
    });
  }, []);

  return (
    <div className="contactoZone my-4">
      <div className="container">
        <h2 className="contact-title bePixeloEmail py-2" data-aos="fade-up">Contáctanos</h2>
        <p className="contact-description" data-aos="fade-up" data-aos-delay="100">
          Si tienes alguna pregunta o deseas más información, por favor, no dudes en contactarnos a través del siguiente formulario o por los medios de contacto que se indican abajo.
        </p>
        <div className="row">
          <div className="col-md-6 py-5" data-aos="fade-right" data-aos-delay="200">
            <form className="contact-form">
              <div className="form-group">
                <label htmlFor="name">Nombre</label>
                <input type="text" id="name" className="form-control" placeholder="Tu Nombre" required />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input type="email" id="email" className="form-control" placeholder="Tu Email" required />
              </div>
              <div className="form-group">
                <label htmlFor="message">Mensaje</label>
                <textarea id="message" className="form-control" rows="5" placeholder="Tu Mensaje" required></textarea>
              </div>
              <button type="submit" className="btn btn-primary">Enviar</button>
            </form>
          </div>
          <div className="col-md-6 contact-info py-5" data-aos="fade-left" data-aos-delay="300">
            <h4>Información de Contacto</h4>
            <p><strong>Teléfono:</strong> +59891445491</p>
            <p><strong>Email:</strong> hola@bepixelo.com</p>
            {/* <p><strong>Dirección:</strong> Calle Falsa 123, Ciudad, País</p>
            <img src={pixeloLogo} alt="Pixelo Logo" className="contact-logo" /> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contacto;
