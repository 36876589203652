import React, { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import pixeloLogo from "../assets/images/pixeloIcon_xs.png";
import "../styles/footer.css";

function Footer() {
  useEffect(() => {
    AOS.init({
      duration: 800,
      offset: 100,
      once: true
    });
  }, []);

  return (
    <footer className="bg-dark text-white p-5" data-aos="fade-up">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-4">
            <img src={pixeloLogo} alt="Pixelo Logo" className="footer-logo" />
            <p className="mt-3">Si pensas en tu página web profesional, piensas en nosotros.</p>
          </div>

          <div className="col-12 col-md-4">
            <h5 className="pixeloOnlyFont">Contacto</h5>
            <ul className="list-unstyled">
              <li>Teléfono: +59891445491</li>
              <li>Email: hola@bepixelo.com</li>
              {/* <li>Dirección: Calle Falsa 123, Ciudad, País</li> */}
            </ul>
          </div>

          <div className="col-12 col-md-4">
            <h5 className="pixeloOnlyFont">Enlaces Útiles</h5>
            <ul className="list-unstyled">
              <li><a href="#home">Inicio</a></li>
              <li><a href="#services">Servicios</a></li>
              <li><a href="#about">Sobre Nosotros</a></li>
              <li><a href="#contact">Contacto</a></li>
            </ul>
          </div>
        </div>
{/* 
        <div className="row mt-4">
          <div className="col text-center">
            <img src={pixeloLogo} className="img-oscilante" alt="Pixelo Logo" />
          </div>
        </div> */}

        <div className="row mt-4">
          <div className="col text-center">
            <p className="mb-0">
              &copy; {new Date().getFullYear()} <span className="brandOnFooter">pixelo</span> - Todos los derechos reservados.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
