import React, { useEffect } from "react";
import Slider from "react-slick";
import AOS from 'aos';
import 'aos/dist/aos.css';

import "../styles/inicio.css";
import "../styles/globalSty.es.css";

import Image1 from "../assets/images/Slider1.png";
import Image2 from "../assets/images/Slider2.png";
import Image3 from "../assets/images/Slider3.png";

function Inicio() {
  useEffect(() => {
    AOS.init({
      duration: 1200,
      offset: 800,
      once: true
    });
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000
  };

  return (
    <div className="home-section py-4">
      <div className="">
        <div className="slider-section" data-aos="zoom-in-up">
          <Slider {...settings}>
            <div>
              <img src={Image1} alt="Descripción de la imagen 1" />
            </div>
            <div>
              <img src={Image2} alt="Descripción de la imagen 2" />
            </div>
            <div>
              <img src={Image3} alt="Descripción de la imagen 3" />
            </div>
          </Slider>
        </div>
        <div className="row py-5" data-aos="fade-up">
          <div className="col">
            <div className="text-center">
              <h1 className="secondaryFont">
                Desbloquea todo tu <br></br>{" "}
                <span className="pixeloOrange">potencial digital</span>
              </h1>
              <p className="offset-md-4 col-12 col-md-4">
                Nuestro equipo te ayudará a establecer tu visión digital
                mediante la creación y el mantenimiento de tu web para impulsar
                el crecimiento.
              </p>
            </div>
          </div>
        </div>
        <div className="row services-section p-5 mt-3" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
      <div className="col-12 text-center">
        <h2 className="secondaryFont">Nuestro Objetivo</h2>
        <p className="pTextStyle col-12 col-md-8 offset-md-2 p-2">
          Queremos mejorar tu presencia online, enfocándonos en ofrecerte un
          servicio de <strong>FACIL ACCESO.</strong> Queremos que vos
          solamente te dediques a pensar cómo te gustaría que sea tu sitio y
          nosotros nos encargamos de la ejecución.
        </p>
      </div>
      <div className="col-12 col-md-4 text-center" data-aos="fade-right" data-aos-delay="100">
        <div className="service-card">
          <h4>Diseño Profesional</h4>
          <p>Nos aseguramos de que tu sitio web tenga un diseño atractivo y profesional que atraiga a tus clientes.</p>
        </div>
      </div>
      <div className="col-12 col-md-4 text-center" data-aos="fade-up" data-aos-delay="200">
        <div className="service-card">
          <h4>Desarrollo Rápido</h4>
          <p>Utilizamos las últimas tecnologías para desarrollar tu sitio web de manera rápida y eficiente.</p>
        </div>
      </div>
      <div className="col-12 col-md-4 text-center" data-aos="fade-left" data-aos-delay="300">
        <div className="service-card">
          <h4>Soporte Continuo</h4>
          <p>Ofrecemos soporte continuo para asegurarnos de que tu sitio web siempre esté funcionando perfectamente.</p>
        </div>
      </div>
    </div>
      </div>
    </div>
  );
}

export default Inicio;
