import './App.css';

import HomePage from './pages/homePage'
import PixeloSpa from './pages/PixeloSpa';

function App() {
  return (
    <div className="">
      <PixeloSpa/>
    </div>
  );
}

export default App;
